<template>
  <div style="text-align: center; background-color: white">
    <van-image
        class=""
        :src="require('@/assets/common/error_pay.png')"
        width="30%"
        height="20%"
        style="background-color:transparent; "
    />
    <p style="font-size:15px"> {{ errorMsg }} </p>
    <div class="closeErrorPage" v-show="show">
      <p>本页面在{{count}}秒后自动关闭</p>
      <button @click="closePage()">关闭</button>
    </div>
  </div>
</template>

<script>
import {Base64} from "js-base64";

export default {
  name: "ErrorPage",
  data() {
    return {
      count: 0,
      timer:false,
      show: true,
      errorMsg: ''
    }
  },
  mounted() {
    this.errorMsg = this.$route.query.msg
    if (this.$route.query.url_type === 'ios') {
      this.show = false
    } else {
      this.clickJump();
    }
  },
  methods: {
    closePage() {
      console.log("跳转url_type：" + this.$route.query.url_type);
      let url_type = this.$route.query.url_type;
      let url = "https://wx.playhy.com/#/";
      //  VIP充值链接特殊处理
      if (url_type.indexOf('vipRecharge_') !== -1) {
        const vipStrValue = url_type.split('_')[1]
        try {
          const vipId = Base64.decode(vipStrValue).split("=")[1]
          // 参数错误
          if (vipId === undefined) {
            this.$toast.fail('专属链接不存在或已失效！')
            url = ''
          }else{
            url += "vip?recharge=" + vipStrValue
          }
        } catch (err) {
          this.$toast.fail('专属链接不存在或已失效！')
          url = ''
        }
      }
      //  安卓网页支付特殊处理
      if (url_type.indexOf('androd_h5_pay_') !== -1) {
        const vipStrValue = url_type.split('androd_h5_pay_')[1]
        try {
          const orderNum = Base64.decode(vipStrValue).split("=")[1]
          // 参数错误
          if (orderNum === undefined) {
            this.$toast.fail('订单号不存在！')
            url = ''
          }else{
            url += 'androd_h5_pay?' + Base64.decode(vipStrValue)
          }
        } catch (err) {
          this.$toast.fail('订单号不存在！')
          url = ''
        }
      }
      switch (url_type) {
        case "dajin":
         url += "dajin";
         break;
        case "icelord":
          url += "icelord";
          break;
        case "credit":
          url += "credit";
          break;
        case "darkxiyou":
          url += "darkxiyou";
          break;
        case "ghlcity":
          url += "ghlcity";
          break;
        case "fkjlb":
          url += "fkjlb";
          break;
        case 'api_credit':
          url = 'http://api.mfms.xhppgames.com/h5/#/credit'
          break;
        case 'chiyou_h5':
          url = 'https://api.sqxh.playhy.com/#/play?gid=bb539f2d4aae41839f043a5ed1927300&login=1'
          break;
        case 'darkxiyou_h5':
          url = 'https://api-pro.darkxiyou.com/#/play?gid=8bdc66d69b46489c8ae171ace27b1074&login=1&pay_login=1'
          break;
        case 'darkxiyou_test_h5':
          url = 'https://api.darkxiyou.com/#/play?gid=8bdc66d69b46489c8ae171ace27b1074&login=1&pay_login=1'
          break;
        case 'legendary_overlord_ios':
          url = 'https://api.legendoflord.com/#/play?gid=a3d3d24157074c86b130bfa299299203&login=1&pay_login=1'
          break;
        case 'legendary_overlord_android':
          url = 'https://api.legendoflord.com/#/play?gid=bbd2ca60a6c044e59d1ce10464b796b7&login=1&pay_login=1'
          break;
        case 'thunderword_h5':
          url = 'https://api-lt.darkxiyou.com/#/play?gid=a895b258073f44bb813e34f533422f17&login=1&pay_login=1'
          break;
        case 'dashencj_h5':
          url = 'https://api-lt.kingverdict.com/#/play?gid=2527c95136f64d178957b30d5b5a9501&login=1&pay_login=1'
          break;
        case 'fkjlb_h5':
          url = 'https://fkjlb.clubtreasurebox.com/#/play?gid=97ea578eb97a4341bf77f45a3b224985'
          break;
        case 'secret':
          url = 'https://wx.playhy.com/#/secret'
          break;
        case 'role':
          url = 'https://wx.playhy.com/#/role'
          break;
        case 'challenge':
          url = 'https://wx.playhy.com/#/challenge'
          break;
        case 'ios_android':
          url = ''
          break;
      }
      window.location.href = url
    },
    //几秒后进入跳转页面
    clickJump(){
      const timejump = 3;
      if(!this.timer){
        this.count = timejump ;
        this.timer = setInterval(()=>{
          if(this.count > 0 && this.count <= timejump ){
            this.count--;
          }else{
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
            //跳转的页面写在此处
            this.closePage();

          }

        },1000)

      }

    },


  },
  beforeDestroy() {    //页面关闭时清除定时器

    clearInterval(this.timer);
  },
}
</script>

<style scoped>
.closeErrorPage {
  font-size: 16px;
  text-align: center;
}
</style>
